// import { canUseDOM } from 'exenv';

export function staticPageLoading(resp = {}, store = {}) {
  return {
    type: 'STATIC_PAGE_LOADING',
    staticPage: resp,
    store
  };
}

export function staticPageLoaded(resp = {}, store = {}) {
  return {
    type: 'STATIC_PAGE_LOADED',
    staticPage: resp,
    store
  };
}

export function staticPageLoadError(error, store = {}) {
  return {
    type: 'STATIC_PAGE_ERROR',
    staticPage: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
