import { canUseDOM } from 'exenv';
import staticPageAction from '../../../../../redux/actions/newsletters/async_newsletters_page_actions';
import log from '../../../../../services/logger_service';

export default function staticData(store) {
  return function fetchData(nextState, replace, callback) {
    const { dispatch } = store;
    // run async
    if (canUseDOM) {
      if (store.getState().config.initialPageLoad === true) {
        if (callback) {
          return callback();
        }
        return false;
      }
      staticPageAction(nextState, store)(dispatch);
      if (callback) {
        return callback();
      }
      return false;
    }
    // run sync
    return staticPageAction(nextState, store)(dispatch)
      .then(function cb() {
        if (callback) {
          return callback();
        }
        return false;
      })
      .catch(function handleServerSideRenderError(error) {
        log.fatal(
          error,
          `Fatal error in onEnter state manager: ${error.message}`
        );
        if (callback) {
          return callback(error);
        }
        return false;
      });
  };
}
