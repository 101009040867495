import get from 'lodash/get';
import * as staticPageActions from '../../action_creators/static_page_action_creators';
// import asyncRecircFeedActions from '../async_recirc_feed_actions';
import notFoundActionCreator from '../../../redux/action_creators/not_found_status_action_creator';
import log from '../../../services/logger_service';
import { hideRecircAction } from '../async_recirc_feed_actions';

export default function prepareNewslettersPage(params, store) {
  return function firstDispatch(dispatch) {
    dispatch(staticPageActions.staticPageLoading({}, store));
    return Promise.resolve()
      .then(function dispatchNewsletterPageActions() {
        dispatch(staticPageActions.staticPageLoaded({}, store));
        hideRecircAction(dispatch)();
        return true;
      })
      .catch(function handleNewsletterPageError(err) {
        const status = get(err, 'response.status') || 500;
        if (status === 404) {
          dispatch(notFoundActionCreator(404, 'NOT_FOUND_STATUS', store));
        } else {
          dispatch(notFoundActionCreator(500, 'ERROR_STATUS', store));
          log.error(err, `Error in wp page actions, message: ${err.message}`);
        }
        dispatch(staticPageActions.staticPageLoadError(err, store));
      });
  };
}
