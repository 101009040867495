import React, { useState, useEffect } from 'react';
import EventModel from '../../../../../utils/tracking/models';
import loadData from './newsletter_page_state_manager';
import loadable from '@loadable/component';

const Section = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Section
  }
);
const SubmissionSuccess = loadable(() =>
  import('../Success/SubmissionSuccess')
);
const MarketoNewsletterForm = loadable(() => import('./MarketoNewsletterForm'));

const NewsletterPageMarketo = () => {
  const [wasSubmitted, setSubmitted] = useState(false);

  useEffect(() => {
    document.body.classList.add('newsletters');
  });

  const handleSubmission = () => {
    setSubmitted(true);

    // Track success event in SpeedCurve
    const submissionSuccessEvent = new EventModel();
    submissionSuccessEvent.pushKeyValueToSpeedCurve(
      'newsletterSubscription',
      1
    );
  };

  return (
    <div>
      <Section name="newsletters__page__header" noPadding bgColor="#F58473">
        <h1>
          Explore <span>Fast Company</span> Newsletters
        </h1>
        <h2>
          Discover new perspectives. Sign up to get our most important coverage
          delivered to your inbox.
        </h2>
        <img
          src="https://assets.fastcompany.com/image/upload/v1675193267/fc-newsletter-header.webp"
          alt="Fast Company email newsletters on mobile device"
          width="346"
          height="90"
          srcSet="https://assets.fastcompany.com/image/upload/dpr_2.0,q_auto:best/v1675789020/fc-newsletter-header.png 692w, https://assets.fastcompany.com/image/upload/dpr_2.0,q_auto:best/v1675789282/fc-newsletter-header_lg.webp 1392w"
          sizes="(max-width: 600px) 346px, (max-width: 730px) 95vw, 696pxpx"
        />
      </Section>

      {wasSubmitted ? (
        <SubmissionSuccess />
      ) : (
        <MarketoNewsletterForm handleSubmission={handleSubmission} />
      )}

      <Section name="newsletters__page__footer">
        <div className="support">
          <h3> Need Help? </h3>
          <h4>
            {' '}
            Contact{' '}
            <a href="malito:newsletters@fastcompany.com" target="_top">
              {' '}
              newsletters@fastcompany.com{' '}
            </a>{' '}
          </h4>
          <h4>
            {' '}
            Visit the{' '}
            <a
              href="https://register.fastcompany.com/fc-preference-center.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Preference Center{' '}
            </a>{' '}
            to manage your account{' '}
          </h4>
        </div>
        <hr />
        <div className="subscribe">
          <h3> Subscribe </h3>
          <h4>
            {' '}
            Unparalleled Journalism.{' '}
            <a
              href="https://www.fastcompany.com/subscribe"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Subscribe{' '}
            </a>{' '}
            to Fast Company Premium{' '}
          </h4>
        </div>
      </Section>
    </div>
  );
};

NewsletterPageMarketo.loadData = store => loadData(store);

export default NewsletterPageMarketo;
